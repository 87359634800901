import { Title } from "@mantine/core";

const Roadmap = () => {
  return (
    <Title order={1} align="center">
      Roadmap
    </Title>
  );
};

export default Roadmap;
