import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: "1px solid",
    borderTopColor: theme.colors.dark[9],
    padding: rem(32),
    paddingTop: rem(48),
    marginBottom: rem(64),
  },
  footer_section: {
    flex: 1,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  logo_mobile: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  copyright: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
  },
  copyright_mobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  footer_text: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[5]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    textAlign: "center",
  },
  all_rights_reserved_text: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[5]
        : theme.colors.gray[7],
    fontSize: "0.75rem",
    textAlign: "center",
    marginTop: "10px",
  },
  logo_text: {
    fontWeight: 700,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[5]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
  },
  link: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[5]
        : theme.colors.gray[7],
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
    ["&:hover"]: {
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },
  highlightedLink: {
    color: "#f90 !important",
    fontWeight: 700,

    "&:hover": {
      textDecoration: "underline !important",
    },
  },
}));
