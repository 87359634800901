import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  title: {
    marginBottom: "4rem",
  },
  pricing_card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: rem(32),
    textAlign: "center",
    padding: "1px",
    width: "100%",
    zIndex: 1,
    borderRadius: rem(16),
    background: "linear-gradient(to bottom,#0f0825d1,rgb(255 255 255 / 0%))",

    ["&::before"]: {
      content: '" "',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: -1,
      padding: 1,
      borderRadius: rem(16),
      background:
        "linear-gradient(to bottom, rgb(255 255 255 / 18%), rgb(8 0 31))",
      "-webkit-mask":
        "linear-gradient(black, black) content-box content-box, linear-gradient(black, black)",
      "-webkit-mask-composite": "xor",
    },
  },
  gold_card: {
    border: `2px solid ${theme.colors.yellow[6]}`, // Customize the color as needed
    boxShadow: `0 0 20px ${theme.colors.yellow[6]}`,
  },
  popular_label: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    padding: "5px 10px",
    borderRadius: "20px",
    fontSize: rem(12),
    fontWeight: 700,
    color: theme.colors.dark[9],
    backgroundColor: theme.colors.yellow[6],
    zIndex: 1,
  },
  plus_card: {
    background: "linear-gradient(to bottom,#0a031e00,rgb(255 255 255 / 8%))",
    ["&::before"]: {
      background: "linear-gradient(to bottom,#231c35c2,rgb(73 62 104))",
    },
  },

  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 34,
    ["&::before"]: {
      content: '" "',
      display: "block",
      height: 1,
      width: "100%",
      backgroundColor: theme.colors.dark[5],
    },
    ["&::after"]: {
      content: '" "',
      display: "block",
      height: 1,
      width: "100%",
      backgroundColor: theme.colors.dark[5],
    },
  },

  features: {
    textAlign: "left",
  },
}));
