export const techStack = [
  "Node.js",
  "TypeScript",
  "React",
  "NPM Workspaces for monorepo",
  "Add any static site generator - Next.js, Gatsby, Astro, Hugo etc.",
  "Add any CSS framework - Tailwind, Bootstrap, Bulma etc.",
  "Write your own API in any language - Python, Go, Java etc.",
];
export const awsServices = [
  "Lambda",
  "API Gateway",
  "DynamoDB",
  "Cognito",
  "S3",
  "CloudFront",
  "Route 53",
  "AWS SES",
  "AWS Pinpoint",
];

export const paymentDetails = [
  "Stripe and Lemon Squeezy integration",
  "Stripe and Lemon Squeezy webhooks",
  "Storing payment details in the database",
  "Detailed documentation for payment processing",
  "Event-driven queue for payment processing",
  "Pricing plans for the landing page",
];

export const blogDetails = [
  "Highly customizable blog",
  "Dark and light mode",
  "Show reading time",
  "SEO friendly",
  "MD and MDX support",
  "Email subscription (coming soon)",
  "Send email to subscribers (coming soon)",
];

export const emailDetails = [
  "High-quality email delivery with AWS SES",
  "Automatically configured DKIM, SPF and Identity",
  "Automated email campaigns with AWS Pinpoint",
  "Automatically add users to email lists",
  "Virtual Deliverability Manager to monitor email deliverability",
  "Email templates or add any html/css email template",
];

export const authDetails = [
  "Authentication and authorization",
  "Users are stored in Cognito and DynamoDB",
  "Sign up, sign in, sign out, forgot password, change password etc. already implemented with the templates",
  "Sign in with email and password",
  "Sign in with Google (detailed guide)",
  "Link custom email and password to Google account",
  "Easily enable sign in with Facebook, Apple, Amazon etc.",
  "No charge from AWS for the first 50.000 monthly active users",
];

export const databaseDetails = [
  "DynamoDB (highly recommended for serverless)",
  "DynamoDB util functions such as get, put, update, delete etc.",
  "Ask for help in the Discord channel if you need help with DynamoDB",
  "Everything is already configured for you",
  "Easily add any database - MySQL, PostgreSQL, MongoDB etc.",
  "Easily connect to any database, even outside of AWS",
];

export const bestPractices = [
  "Separated accounts for dev and prod",
  "Secure your AWS accounts",
  "Every infrastructure component is defined as code",
  "Add new account with one extra line of code (account name)",
  "Detailed documentation for every resource",
  "Clean, reusable and easy to understand code",
];

export const moreDetails = [
  "Customizable - add any library, framework, service etc.",
  "Serverless - pay only for what you use",
  "AWS Budgets - get notified when you are about to exceed your budget (1$, 10$, 50$, 100$, 500$, 1000$)",
  "Monorepo - NPM Workspaces",
  "Prettier",
  "Eslint",
];

export const generalDetails = [
  "Learn AWS - Improve highly demanded AWS skills with best practices",
  "Domain configuration - everything is automagically configured for you",
  "GitHub CI/CD - automatically deploy your code (dev and prod).",
  "SEO - sitemap, robots.txt, meta tags, social media tags etc.",
  "Cache - All frontend apps are cached with CloudFront. You can also enable caching for your API (Disabled by default).",
  "Discord community - ask for help in the Discord channel",
  "Detailed documentation - every resource is documented",
  "Detailed guides - how to add new features, how to deploy etc.",
];

export const heroWhyThisLists = [
  "Start your SaaS today. Save time and money",
  "Everything is covered - from payment to emai",
  "Learn AWS with best practices - improve your skills",
  "High-quality code - clean, reusable and easy to understand",
  "No third-party services - no boilerplate headache",
];
