import { Text } from "@mantine/core";

const License = () => {
  return (
    <Text align="left">
      <h1>License</h1>
      <p>
        <strong>
          By purchasing Scale to Zero AWS kit, you are permitted to access the
          codebase and utilize the code for your projects through a license. To
          fully comprehend the terms of our license, please carefully review the
          following information.
        </strong>
      </p>
      <p>
        Scale to Zero AWS maintains ownership of the codebase and provides its
        usage based on the following terms and conditions. The license allows
        you to utilize the code to develop an end product, granting you an
        ongoing, non-exclusive, worldwide license. You have the right to use the
        codebase to create multiple end products, whether for personal use or
        for various clients.
      </p>
      <h3>You are allowed to do with the codebase:</h3>
      <ul>
        <li>
          <strong>
            You are permitted to create multiple projects on the top of the
            codebase
          </strong>
        </li>
        <li>
          <strong>
            You are entitled to sell any number of your projects built on the
            top of the codebase
          </strong>
        </li>
      </ul>
      <h3>You are not allowed to do this with the codebase:</h3>
      <ul>
        <li>
          <strong>
            You are not allowed to create a project that sells the codebase
            itself
          </strong>
        </li>
        <li>
          <strong>
            You are not allowed to create a project that sells the codebase
            itself with minor changes
          </strong>
        </li>
        <li>
          <strong>You are not allowed to redistribute the codebase</strong>
        </li>
        <li>
          <strong>
            You are not allowed to share your license with any companies or
            individuals
          </strong>
        </li>
      </ul>

      <h2>Refund Policy</h2>
      <p>
        By purchasing the Scale to Zero AWS kit, you agree to the terms and
        conditions of our license, which grants you access to the codebase and
        the right to use it for your projects. As such, we do not offer refunds.
      </p>
      <p>
        If you are unsure or have any further questions about how the license
        works for your case, please contact us via{" "}
        <a href="mailto:contact@scaletozeroaws.com">
          contact@scaletozeroaws.com
        </a>
      </p>
    </Text>
  );
};

export default License;
