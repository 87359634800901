import React from "react";

import "./index.css";

const Banner: React.FC = () => {
  return (
    <div className="v2-banner">
      <p>
        🎉 Exciting news! V2 is here! 🚀. Read more about it{" "}
        <a
          className="v2-banner-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.scaletozeroaws.com/whats-new/2024-10-27"
        >
          here
        </a>
        .
      </p>
    </div>
  );
};

export default Banner;
