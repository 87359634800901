import { Accordion, Container, Title, createStyles, rem } from "@mantine/core";

const { VITE_STAGE_DOMAIN } = import.meta.env;

const docsLink = `https://docs.${VITE_STAGE_DOMAIN}`;
const techStackLink = `${docsLink}/intro#tech-stack`;

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,
    marginBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function FAQ() {
  const { classes } = useStyles();
  return (
    <section id="faq">
      <Container size="sm" className={classes.wrapper}>
        <Title align="center" className={classes.title}>
          Frequently Asked Questions
        </Title>

        <Accordion variant="separated">
          <Accordion.Item className={classes.item} value="refund">
            <Accordion.Control>Can I get a refund?</Accordion.Control>
            <Accordion.Panel>
              Of course. If you are unsatisfied with the kit, you can get a
              refund within 7 days of purchase. Please either write us at live
              support or send an email to{" "}
              <a href="mailto:support@scaletozeroaws.com">
                {" "}
                support@scaletozeroaws.com{" "}
              </a>
              .
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="why-this-kit">
            <Accordion.Control>
              How is this kit different from other boilerplates?
            </Accordion.Control>
            <Accordion.Panel>
              I am glad you asked! Check out the{" "}
              <a href="/why-this-kit">Why This Kit</a> page for more
              information.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            className={classes.item}
            value="what-is-scale-to-zero"
          >
            <Accordion.Control>What is Scale to zero?</Accordion.Control>
            <Accordion.Panel>
              Scale to zero refers to the ability of a serverless application to
              automatically scale down to zero resources when there is no
              traffic and then scale up to handle traffic when needed. It can
              help optimize costs by reducing the resources consumed when the
              application is not in use. In other words, you only pay for what
              you use.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="why-serverless">
            <Accordion.Control>Why Serverless?</Accordion.Control>
            <Accordion.Panel>
              Serverless technologies feature automatic scaling, built-in high
              availability, and a pay-for-use billing model to increase agility
              and optimize costs. These technologies also eliminate
              infrastructure management tasks like capacity provisioning and
              patching, so you can focus on writing code that serves your
              customers. Read more about serverless technologies{" "}
              <a href="https://aws.amazon.com/serverless/">here.</a>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="why-aws">
            <Accordion.Control>Why AWS?</Accordion.Control>
            <Accordion.Panel>
              <p>Using AWS has a lot of benefits. Some of them are:</p>

              <ul>
                <li>
                  <strong>
                    <a href="https://aws.amazon.com/free/">
                      Very generous free tiers
                    </a>
                  </strong>
                </li>
                <li>
                  <strong>Scalable and high-performance</strong>
                </li>
                <li>
                  <strong>Flexible, Secure and easy to use</strong>
                </li>
                <li>
                  <strong>
                    Learning AWS is one of the most in-demand skills
                  </strong>
                </li>
                <li>
                  <strong>Fastest-Growing Public Cloud</strong>
                </li>
                <li>
                  <strong>
                    <a href="https://aws.amazon.com/application-hosting/benefits/">
                      And more benefits
                    </a>
                  </strong>
                </li>
              </ul>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="why-this-kit">
            <Accordion.Control>Why do I need this kit?</Accordion.Control>
            <Accordion.Panel>
              Whenever I worked on multiple side projects, I always faced the
              issue of starting with a new folder structure, infrastructure, and
              service code. Copying from existing ones and replacing and
              renaming really takes time, and I saw the problem. Imagine the
              hours you spend just to release basic landing. Instead, focus on
              your idea and build it on top of this kit. It has everything you
              need to validate your idea or start to build.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="tech-stack">
            <Accordion.Control>What is the tech stack?</Accordion.Control>
            <Accordion.Panel>
              Please read the tech stack in{" "}
              <a href={`${techStackLink}`}>this section</a> of the docs.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="license">
            <Accordion.Control>
              What am I allowed or not allowed to do with the kit?
            </Accordion.Control>
            <Accordion.Panel>
              You are allowed to build unlimited personal or commercial
              projects. Read more about in the <a href="license">license</a>{" "}
              page
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="maintenance">
            <Accordion.Control>Is it regularly maintained?</Accordion.Control>
            <Accordion.Panel>
              Definitely! By listening to the requested features and bugs from
              the customers, it will always be regularly maintained. More
              features are coming soon. Additionally, you can always check when
              this kit's codebase is updated on the main page.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="after-purchase">
            <Accordion.Control>
              What happens after I purchase this kit?
            </Accordion.Control>
            <Accordion.Panel>
              You’ll get two emails—an invitation to the private GitHub repo and
              Discord server. The latter is optional, but I highly recommend
              joining it to get the support.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="difficulties">
            <Accordion.Control>
              What if I run into some difficulties?
            </Accordion.Control>
            <Accordion.Panel>
              We’re always here to help you! You can reach us by using email,
              chat, or Discord. You’re guaranteed to get the help to run your
              kit.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="feature-or-bug">
            <Accordion.Control>
              How can I request a feature or file a bug?
            </Accordion.Control>
            <Accordion.Panel>
              For requesting a feature or voting for existing features, use our{" "}
              <a
                href="https://scaletozeroaws.featurebase.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                feature hub
              </a>
              . You can request or vote without buying the kit. For bugs, feel
              free to open issues on GitHub private repo.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="who">
            <Accordion.Control>Who can use this kit?</Accordion.Control>
            <Accordion.Panel>
              It’s very user-friendly and doesn’t require any AWS skills. It’s
              beneficial for:
              <ul>
                <li>Indie hackers</li>
                <li>Teams</li>
                <li>Anyone wants to learn AWS Serverless.</li>
              </ul>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
}
