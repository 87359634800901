import { Container } from "@mantine/core";

import AboutMe from "@/components/about-me";
import Features from "@/components/features";
import Hero from "@/components/hero";
import FAQ from "../../components/faq";
import Pricing from "../../components/pricing";
import KitAdvantages from "../../components/why-this-kit";

const Home = () => {
  return (
    <Container size="lg">
      <Hero />
      <Features />
      <KitAdvantages />
      <AboutMe />
      <Pricing />
      <FAQ />
    </Container>
  );
};

export default Home;
