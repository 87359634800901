import { Text } from "@mantine/core";

import "../../index.css";

const VsSstDev = () => {
  return (
    <div className="why-this-kit">
      <Text>
        <h2>Scale to Zero AWS kit vs sst.dev</h2>

        <p>
          sst.dev is a great tool for deploying serverless apps to AWS. It might
          look similar to the Scale to Zero AWS kit. However, there are key
          differences.
        </p>

        <div className="comparison-table">
          <table>
            <thead>
              <tr>
                <th>Aspect</th>
                <th>Scale to Zero AWS kit</th>
                <th>sst.dev</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Overhead</td>
                <td>Simple and easy to understand. No third-party tool.</td>
                <td>
                  Overhead of another third-party tool. Requires additional
                  learning curve and maintenance.
                </td>
              </tr>
              <tr>
                <td>Customization</td>
                <td>
                  Directly uses AWS CDK. Full control over the infrastructure.
                </td>
                <td>
                  Customization - sst.dev is built on top of AWS CDK. If there's
                  a new feature in AWS CDK, you need to wait for sst.dev to
                  implement it and release a new version.
                </td>
              </tr>
              <tr>
                <td>Reliability</td>
                <td>Does not use any third-party tools.</td>
                <td>
                  How long will sst.dev be maintained? How long will they
                  support the existing features? They are already moving away
                  from CDK that they were built on top of.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          <span className="highlight">
            1. Overhead of another third-party tool
          </span>
          - sst.dev is highly opinionated and is an extension of AWS CDK. AWS
          CDK already abstracts away the complexity of CloudFormation. Why do
          you need another abstraction layer? Why do you need more complexity?{" "}
          <br />- Scale to Zero AWS kit is designed to be simple and easy to
          understand. Instead of being opinionated, it puts together the
          resources using AWS best practices.
        </p>

        <p>
          <span className="highlight">2. Customization</span> - sst.dev is built
          on top of AWS CDK. If there's a new feature in AWS CDK, you need to
          wait for sst.dev to implement it and release a new version. <br />-
          Scale to Zero AWS kit directly uses AWS CDK and every new feature is
          available immediately. You have full control over the infrastructure.
        </p>

        <p>
          <span className="highlight">3. Reliability </span> - sst.dev requires
          you to install the sst packages. You need to maintain yet another
          tool. What if they stop maintaining the tool or introduce breaking
          changes? Sure, AWS CDK can also introduce breaking changes, but they
          are less likely to do so. Even if they do, there's a dedicated AWS CDK
          team to fix the issue immediately. Currently, sst.dev is moving away
          from CDK. Reliability is a big concern when it comes to third-party
          tools. <br />- Scale to Zero AWS kit does not use any third-party
          tools.
        </p>
      </Text>
    </div>
  );
};

export default VsSstDev;
