import styled from "styled-components";

import "./landing.css";

const { VITE_STAGE_DOMAIN } = import.meta.env;

const KitImageContainer = styled.div`
  width: 50%; /* Default width for desktop */

  @media (max-width: 767px) {
    width: 85%; /* Width for mobile devices */
  }
`;

const KitAdvantages = () => {
  return (
    <section id="why-this-kit">
      <div className="container">
        <p className="section-title">
          {" "}
          <span className="introduction-highlight">
            Play with the demo code{" "}
          </span>{" "}
          below to see how it works.
        </p>
        {/* <p className="description">
          We understand that managing multiple AWS accounts and environments can
          be challenging. That's why our kit comes with pre-configured dev and
          prod environments, allowing for easy management and team scalability.
          Leverage infrastructure-as-code using AWS CDK, and benefit from GitHub
          CI/CD for seamless deployment.
        </p> */}
        {/* <div className="kit-image">
          <KitImageContainer>
            <LogoSvg />
          </KitImageContainer>
        </div> */}
        <div>
          {/* <h3>
            {" "}
            <span className="introduction-highlight">
              Play with the demo code{" "}
            </span>{" "}
            below to see how it works.
          </h3> */}

          <div className="editor-demo">
            <iframe
              src="https://stackblitz.com/edit/scaletozeraws-demo?embed=1&file=packages%2Fmain%2Finfra%2Froot.ts&hideNavigation=1&theme=dark&view=editor&showSidebar=1&hideNavigation=0&hideExplorer=0&hidedevtools=0"
              title="StackBlitz Editor"
              width="100%"
              height="600px"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KitAdvantages;
