import { Text } from "@mantine/core";

import VercelDramaImage from "@/assets/vercel-drama.png";
import "../../index.css";

const VsNextJsBoilerplatesAndVercel = () => {
  return (
    <div className="why-this-kit">
      <Text>
        <h2>Scale to Zero AWS kit vs Next.js boilerplates</h2>

        <p>
          A quick comparison between Scale to Zero AWS kit and Next.js.{" "}
          <span
            style={{
              textDecoration: "underline",
            }}
          >
            Scroll down for more details{" "}
          </span>
          .
        </p>
        <div className="comparison-table">
          <table>
            <thead>
              <tr>
                <th>Aspect</th>
                <th>Scale to Zero AWS kit</th>
                <th>Next.js boilerplates / Vercel</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Deployment Process</td>
                <td>Simple configuration and one-command deployment.</td>
                <td>
                  Requires multiple steps and account setups for third-party
                  services.
                </td>
              </tr>
              <tr>
                <td>Customization</td>
                <td>Language/framework agnostic, fully customizable.</td>
                <td>Limitations on backend language and frontend framework.</td>
              </tr>
              <tr>
                <td>Cost</td>
                <td>
                  Generous free tier from AWS. Pay as you go. Configured budget
                  alarms to avoid surprises.
                </td>
                <td>
                  Complicated pricing model. Expensive. Pay for all third-party
                  services.
                </td>
              </tr>
              <tr>
                <td>Control Over Infrastructure</td>
                <td>You own your infrastructure. Full control.</td>
                <td>
                  Limited control, especially for infrastructure modifications.
                </td>
              </tr>

              <tr>
                <td>Knowledge</td>
                <td>
                  Learn how to work with AWS resources using best practices.
                </td>
                <td>
                  Learn how to deploy a boilerplate to Vercel. Learn how to add
                  third-party services.
                </td>
              </tr>

              <tr>
                <td>Popularity</td>
                <td>
                  AWS is the most popular cloud provider. It increases your
                  chance of getting hired or promoted. Your app can also be
                  acquired by companies.
                </td>
                <td>Vercel is less popular. It's built on top of AWS.</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          Almost all Next.js boilerplates are designed to be deployed to Vercel.
          Before starting to talk about Vercel, let's talk about these
          boilerplates.
        </p>
        <p>
          <span className="highlight">
            1. The biggest problem with these boilerplates is the overhead of
            the third-party tools.
          </span>{" "}
          It requires many manual steps to get started. You need many accounts
          and configurations in the beginning. For example, open a new account
          for an email sender provider, create a new account for a database,
          sign up for Vercel, and so on. They promote it saves time, but is it
          really? I guess if you are an absolute beginner, maybe it saves some
          time. <br />- With the Scale to Zero AWS kit, you don't need to sign
          up for any third-party services. All you need to do is add your domain
          to the config file and deploy with one command. That's it!
        </p>
        <p>
          <span className="highlight">
            2. Customization is another problem.
          </span>{" "}
          You need to learn how to customize the boilerplate. Can I write part
          of my backend in Rust? No. What about Python or Go? No, you're stuck
          with the language that the boilerplate is written in. What about
          Angular or Vue? Nope, only Next.js.. What if you need to have a queue
          system and a worker? Good luck with that. <br />- - Scale to Zero AWS
          kit is designed to be fully customizable. It's language/framework
          agnostic. You can write your backend in any language that you're
          comfortable with. For example, write the auth logic in Node.js, and
          write performance-critical parts in Rust. You're also not limited to
          React. Use any static site generator or frontend framework. In the
          end, it all cares about the compiled code (/dist folder). AWS has a
          lot of services that you can easily integrate. The kit already comes
          with a lot of serverless resources that you can use.
        </p>
        <p>
          <span className="highlight">3. Knowledge.</span> No one gets hired or
          promoted because they know how to deploy a boilerplate to Vercel. On
          the other hand, knowing how to work with AWS resources can definitely
          land you a big gig! <br />- Scale to Zero AWS kit is not only
          deploying your serverless app to AWS but also teaches you how to work
          with AWS resources using best practices.
        </p>

        <h2>Scale to Zero AWS kit vs Vercel</h2>
        <p>
          Now, let's talk about Vercel! Honestly, Vercel is not a bad platform,
          unless you're planning to only host your side projects or burn money
          (and time).
        </p>

        <img
          className="vercel-drama"
          src={VercelDramaImage}
          alt="Vercel Drama"
        />

        <p>
          <span className="highlight">1. Vercel is expensive.</span> It's not a
          secret. They have a quite complicated pricing model. And always be
          ready to get a surprise email or bill. After all, it's an AWS wrapper.
          Of course, they need to make money. <br />- AWS itself has a very
          generous free tier. Check out this link for more information. With the
          serverless architecture, you only pay for what you use. The bigger
          your app gets, the more you pay, but it will always be way cheaper
          than Vercel.
        </p>
        <p>
          2. Vercel has a nice UX. No one denies that. And, it's well integrated
          with Next.js and it's also easy to deploy. Fair enough!{" "}
          <span className="highlight">
            But what if you need to modify the infra? How much control do you
            have over your app?{" "}
          </span>{" "}
          Well, not much. <br />- Scale to Zero AWS kit automates the whole
          infrastructure. It's written in AWS CDK as IaC (Infrastructure as
          Code). You can easily modify, add, or remove resources. Full control
          over your app. It also comes with a GitHub CI/CD pipeline. If you miss
          a feature, then request a feature and it will be added in the next
          release.
        </p>
        <p>
          <span className="highlight">3. Unpopular opinion:</span> Most
          companies will not acquire your app if it's a Next.js boilerplate
          deployed to Vercel. This itself requires a separate blog post. But in
          short, be aware of the lock-in effect. <br />- Yes, with the Scale to
          Zero AWS, you are also locked into AWS. But, AWS is already the most
          popular cloud provider.
        </p>
      </Text>
    </div>
  );
};

export default VsNextJsBoilerplatesAndVercel;
