import { gtag_report_conversion } from "@/util";
import { Button, Card, Flex, List, Stack, Text } from "@mantine/core";
import { TickSquare } from "iconsax-react";

import { PlatinumCardProps } from "../../types/pricing";
import { useStyles } from "./styles";

const PlatinumCard = ({ package_data }: PlatinumCardProps) => {
  const handleContactClick = () => {
    gtag_report_conversion("mailto:");
    window.location.href = "mailto:sales@scaletozeroaws.com";
  };

  const { classes } = useStyles();
  const { name, price, bestFor, features, CTA } = package_data;

  return (
    <Card padding="none" className={classes.pricing_card}>
      <Stack pt={56} px={24} pb={32} spacing={48}>
        <Stack>
          <Text size="lg">{name}</Text>
          <Text weight="bold" color="white" size={"1.5rem"}>
            {typeof price === "string" && price}
          </Text>
          <Flex justify="center" align="center" gap="sm">
            <Text size="sm">{bestFor ? bestFor : <>&nbsp;</>}</Text>
          </Flex>
        </Stack>
        <div className={classes.divider}></div>
        <List
          withPadding
          size="sm"
          icon={<TickSquare size="16" color="#697689" variant="Bulk" />}
          className={classes.features}
        >
          <Text mb="sm">Everything from Gold, plus</Text>

          {features.map((feature) => (
            <List.Item mb="sm" key={feature}>
              {feature}
            </List.Item>
          ))}
        </List>
        <Button
          variant="default"
          my="xl"
          radius="xl"
          fullWidth
          onClick={handleContactClick}
        >
          Contact us
        </Button>
      </Stack>
    </Card>
  );
};

export default PlatinumCard;
