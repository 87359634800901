import { Box, Container } from "@mantine/core";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { Outlet } from "react-router-dom";
import { useStyles } from "./styles";

const MainLayout = () => {
  const { classes } = useStyles();

  return (
    <main className={classes.main}>
      <Container size="lg" className={classes.container}>
        <Navbar />
        <Box>
          <Outlet />
        </Box>
      </Container>
      <Footer />
    </main>
  );
};

export default MainLayout;
