import { Suspense } from "react";
import { LoadingOverlay } from "@mantine/core";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import MainLayout from "./layouts/main-layout";
import Demo from "./pages/demo";
import Docs from "./pages/docs";
import Home from "./pages/home";
import License from "./pages/license";
import PrivacyPolicy from "./pages/privacy-policy";
import Roadmap from "./pages/roadmap";
import TermsOfService from "./pages/terms-of-service";
import VsNextjsAndVercel from "./pages/why-this-kit/nextjs_and_vercel";
import VsSstDev from "./pages/why-this-kit/sst";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/demo",
        element: <Demo />,
      },
      {
        path: "/docs",
        element: <Docs />,
      },
      {
        path: "/roadmap",
        element: <Roadmap />,
      },
      {
        path: "/terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "/vs-nextjs-and-vercel",
        element: <VsNextjsAndVercel />,
      },
      {
        path: "/why-this-kit",
        element: <Navigate to="/vs-nextjs-and-vercel" />,
      },
      {
        path: "/vs-sst-dev",
        element: <VsSstDev />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/license",
        element: <License />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export function AppRouter() {
  return (
    <Suspense fallback={<LoadingOverlay visible={true} overlayBlur={2} />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default AppRouter;
