import { Title } from "@mantine/core";

const Demo = () => {
  return (
    <Title order={1} align="center">
      Demo
    </Title>
  );
};

export default Demo;
