/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let gtag: any;

export function gtag_report_conversion(url: any) {
  const callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-11247170028/pe12CNLJ8LYYEOzjiPMp",
    transaction_id: "",
    event_callback: callback,
  });
  return false;
}
