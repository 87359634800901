import { Text } from "@mantine/core";

const TermsOfService = () => {
  return (
    <Text align="left">
      <h1>Terms of Service</h1>
      <p>
        By accessing or using our website (
        <a href="https://scaletozeraws.com">https://scaletozeraws.com</a>) and
        purchasing our Kit, you agree to be bound by these Terms of Service and
        all applicable laws and regulations. If you do not agree to these Terms,
        you are prohibited from using or accessing this website or any other
        services Scale to Zero AWS provides.
      </p>
      <h2>Privacy</h2>
      <p>
        Please read our privacy policy (<a href="privacy-policy">link</a>) for
        more information.
      </p>
      <h2>License</h2>
      <p>
        Read our license (<a href="license">link</a>) for more details.
      </p>
      <h2>Security</h2>
      <p>
        Scale to zero AWS does not process any order payments through the
        website. All payments are processed securely through Lemon Squeezy, a
        third-party online payment provider. Read more about Lemon Squeezy’s
        privacy (
        <a href="https://www.lemonsqueezy.com/privacy">
          https://www.lemonsqueezy.com/privacy
        </a>
        ) and term (
        <a href="https://www.lemonsqueezy.com/terms">
          https://www.lemonsqueezy.com/terms
        </a>
        ).
      </p>
      <h2>Intellectual Property</h2>
      <p>
        The materials on this website, including intellectual property, are
        owned by or licensed to Scale to Zero AWS and safeguarded by copyright
        and trademark laws. Users are granted permission to download one copy of
        the materials.
      </p>
    </Text>
  );
};

export default TermsOfService;
