const { VITE_STAGE_DOMAIN } = import.meta.env;

const docsLink = `https://docs.${VITE_STAGE_DOMAIN}`;

const Features = () => {
  const handleDocsButtonClick = () => {
    window.open(docsLink, "_blank");
  };

  const handlePricingButtonClick = () => {
    const pricingSection = document.getElementById("pricing")!;
    pricingSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section id="features">
      <h2 className="feature-section-title">
        {" "}
        <span className="introduction-highlight-4"></span> Much more than just a
        kit{" "}
      </h2>

      <div className="features-container">
        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Prod and Dev accounts</h3>
            <p className="feature-description">
              Say goodbye to the hassle of setting up AWS infrastructure from
              scratch. Our kit provides separate production and development
              accounts to eliminate complexities and allow you to focus on{" "}
              <span className="header-highlight">only building fast</span>.
            </p>
          </div>
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="aws-accounts.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="feature-container-reverse">
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/aws-pinpoint-marketing.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="feature-text-box">
            <h3 className="feature-title">Email marketing</h3>
            <p className="feature-description">
              <span className="header-highlight">
                Send marketing & promotional emails
              </span>{" "}
              to your customers with AWS Pinpoint and SES. Later on, check out
              the detailed analytics to see how your campaigns are performing.
              Users are automatically added to the mailing list when they sign
              up or make a purchase.
            </p>
          </div>
        </div>

        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Integrated Features</h3>
            <p className="feature-description">
              Benefit from seamless integrations with{" "}
              <span className="introduction-highlight">Stripe</span> and{" "}
              <span className="introduction-highlight">Lemon Squeezy</span> for
              a fully integrated payment system.{" "}
              <span className="header-highlight">
                Authentication and authorization are built-in
              </span>{" "}
              using AWS Cognito, with support for login with Google and other
              platforms.
            </p>
          </div>

          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              loop
              playsInline
              muted
              preload="metadata"
            >
              <source src="/demo.mov" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="feature-container-reverse">
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/devEx.mov" type="video/mp4" />
            </video>
          </div>

          <div className="feature-text-box">
            <h3 className="feature-title">Developer Experience</h3>
            <p className="feature-description">
              Our kit supports separate frontend apps for landing,
              authentication, and dashboard, while the backend is powered by AWS
              Lambda and API Gateway.{" "}
              <span className="header-highlight">
                Write code in your preferred language & framework
              </span>{" "}
              and{" "}
              <span className="header-highlight">
                use any static theme you like for the frontend
              </span>
            </p>
          </div>
        </div>

        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Cost Efficiency</h3>
            <p className="feature-description">
              Scale to Zero AWS ensures cost efficiency by automatically scaling
              your resources to zero when not in use.{" "}
              <span className="header-highlight">
                Pay only for what you use
              </span>
              , optimizing your budget and maximizing your ROI.
            </p>
          </div>
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/aws-cost-efficiency.mov" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="feature-container-reverse">
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/scale-to-zero.mov" type="video/mp4" />
            </video>
          </div>
          <div className="feature-text-box">
            <h3 className="feature-title">
              Production-Ready & Highly-Scalable
            </h3>
            <p className="feature-description">
              Our kit follows AWS's{" "}
              <span className="introduction-highlight">best practices</span> for
              serverless architecture, guaranteeing{" "}
              <span className="underline-text-default">
                high scalability, resilience, and performance.
              </span>{" "}
              Scale your applications effortlessly to handle increased demand
              without compromising on quality.
            </p>
          </div>
        </div>

        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Generous free tiers</h3>
            <p className="feature-description">
              AWS offers very generous free tiers for the services we use. With
              the Scale to Zero Kit, you only pay for what you use, allowing you
              to keep costs low while your product grows exponentially.
            </p>
          </div>

          <div className="feature-card-free-tier">
            <div className="card-description">
              <div>
                <ul className="card-ul">
                  <li>
                    Cognito (Authentication and authorization) has a free tier
                    of{" "}
                    <span className="highlight-text-default">
                      50,000 monthly active users{" "}
                    </span>{" "}
                  </li>
                  <li>
                    The AWS Lambda free tier includes{" "}
                    <span className="highlight-text-default">
                      one million requests
                    </span>{" "}
                    per month
                  </li>
                  <li>
                    The Amazon API Gateway free tier includes
                    <span className="highlight-text-default">
                      {" "}
                      one million API calls
                    </span>
                  </li>
                  {/* <li>
                    AWS SES (Email Service) has a free tier of {""}
                    <span className="highlight-text-default">
                      62,000 emails per month
                    </span>
                  </li> */}
                  <li>
                    AWS Cloudfront (CDN & Caching) has a free tier of{" "}
                    <span className="highlight-text-default">1 TB</span> of data
                    transfer out per month and{" "}
                    <span className="highlight-text-default">
                      10 million requests
                    </span>{" "}
                    per month
                  </li>

                  <li>
                    AWS Event Scheduler has a free tier of{" "}
                    <span className="highlight-text-default">
                      14,000,000 invocations per month
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="solutions-container">
        Unlock the full potential of AWS serverless development with the Scale
        to Zero AWS Kit. Streamline your infrastructure, enhance cost
        efficiency, and build scalable applications with great developer
        experience <span className="header-highlight">in minutes</span>. Get
        started now!
      </div> */}

      <div className="solutions-container">
        With one deployment command, you will have the whole infrastructure and
        functionality ready to use. Streamline your infrastructure, enhance cost
        efficiency, and build scalable applications with great developer
        experience <span className="header-highlight">in minutes</span>. Get
        started now!
      </div>

      <div className="features-button-container">
        <button className="btn-primary" onClick={handlePricingButtonClick}>
          Get started
        </button>
        <button className="btn-secondary" onClick={handleDocsButtonClick}>
          Docs
        </button>
      </div>
    </section>
  );
};

export default Features;
