import { createStyles } from "@mantine/core";

export const useStyles = createStyles({
  main: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: 1,
    width: "100%",
  },
});
