import { ReactComponent as TwitterIcon } from "@/assets/twitter-icon.svg";
import "./landing.css";

const AboutMe = () => {
  const handleTwitterIconClick = () => {
    window.open("https://twitter.com/javid_abd", "_blank");
  };
  return (
    <section id="about-me">
      <div className="about-me-container">
        <div className="about-me-text">
          <p>
            I always wasted many hours to setup the infrastructure and write the
            initial code for my projects. Copying from previous projects was not
            an optimal solution because I had to change many parts manually. I
            saw the problem and jumped into action to solve it.
          </p>
          <p>
            I created this kit to help developers like me to save hundreds of
            hours and focus on business logic. This kit also teaches you AWS
            best practices. Well-known companies like Vercel, and Netlify are
            built on top of AWS lambdas, meaning our kit will always be less
            expensive and more flexible than theirs.
          </p>
        </div>

        <div className="about-me-image-box">
          <img
            src="/about-me.jpeg"
            className="about-me-image"
            alt="Scale to Zero kit author"
          />
          <TwitterIcon
            className="twitter-icon"
            onClick={handleTwitterIconClick}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
