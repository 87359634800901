import { Title } from "@mantine/core";

const Docs = () => {
  return (
    <Title order={1} align="center">
      Docs
    </Title>
  );
};

export default Docs;
