/* eslint-disable @typescript-eslint/no-explicit-any */

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faClone,
  faCloud,
  faCodeBranch,
  faCogs,
  faHandshake,
  faMoneyCheckAlt,
  faMoon,
  faRocket,
  faScrewdriverWrench,
  faTasks,
  faUserLock,
  faVihara,
} from "@fortawesome/free-solid-svg-icons";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

import Banner from "./banner";
// Import the Banner component
import AppRouter from "./rotuer";
import { ThemeProvider } from "./theme/theme-provider";

const tagManagerArgs = {
  gtmId: "GTM-P32JZZ6",
};

library.add(
  faMoon,
  faRocket,
  faCloud,
  faScrewdriverWrench,
  faVihara,
  faUserLock,
  faMoneyCheckAlt,
  faCodeBranch,
  faCogs,
  faClone,
  faHandshake,
  faTasks
);

ReactGA.initialize("G-ZQZZYRZFWQ");

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <ThemeProvider>
      <Banner />
      <AppRouter />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        style={{ background: "#2B373B", justifyContent: "center" }}
        buttonStyle={{
          color: "#fff",
          fontSize: "13px",
          background: "#1971c2",
          marginTop: "0px",
          border: "none",
          borderRadius: "4px",
          padding: "8px 12px",
        }}
        contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
        expires={150}
      >
        This website uses cookies to authenticate the user for demo projects and
        enhance the user experience. By using the website, you agree to our use
        of cookies.
      </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
